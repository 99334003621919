/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "eu-north-1",
  aws_cognito_identity_pool_id:
    "eu-north-1:55c5f6b4-9e57-482d-833f-146c88b68d81",
  aws_cognito_region: "eu-north-1",
  aws_user_pools_id: "eu-north-1_d0iDgS9Rz",
  aws_user_pools_web_client_id: "4qjhni0ntpfbg0dclqbfi8rihl",
  oauth: {},
  aws_cognito_username_attributes: [],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ["EMAIL"],
  aws_cognito_mfa_configuration: "OPTIONAL",
  aws_cognito_mfa_types: ["SMS", "TOTP"],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [
      "REQUIRES_LOWERCASE",
      "REQUIRES_UPPERCASE",
      "REQUIRES_NUMBERS",
      "REQUIRES_SYMBOLS",
    ],
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
  aws_appsync_graphqlEndpoint:
    "https://hc3do3s46rhfhdxc5vkk4zr3xu.appsync-api.eu-north-1.amazonaws.com/graphql",
  aws_appsync_region: "eu-north-1",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
